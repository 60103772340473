@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&family=Syne:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@600;700&display=swap');

$text_color_default: black;
$text_color_accent: #1C174A;
$text_color_secondary: #6D6D6D;
$text_color_extra_accent: #F076DB;
$text_color_error: #C81D1D;

//$surface: white;
$surface: #ACAACA;
$title_color: white;
$title_bg: #008282;
$bg_color_accent: #008282;
$bg_color_default: #060606;
$bg_app: #008282;

$bg_button: #C3C3C3;
$br_color: #888888;
$color_button: #060606;

$font_family_default: 'Lexend Deca', monospace;
$font_family_secondary: 'Syne', monospace;
$font_family_mono: 'Roboto Mono', monospace;

$header-max-width: 1400px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 767px;

// Tablets and small desktops
$screen-lg-min: 1025px;

$screen-xlg-min: 1280px;

// Large tablets and desktops
$screen-xl-min: 1900px;

// Small devices
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}

// Medium devices
@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}

// Large devices
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}

@mixin xlg {
	@media (min-width: #{$screen-xlg-min}) {
		@content;
	}
}

// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}

@mixin landscape {
	@media (max-width: 971px) and (max-height: 450px) {
		@content;
	}
}