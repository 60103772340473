@import "../../styles/defs";

$size_p_1: 52px;
$size_p_2: 62px;
$size: $size_p_1 *2;

.loading {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: var(--x6);
	gap: var(--x5);
	background: var(--bg-color-default);
	//border: 1px solid var(--border-secondary);
	//box-shadow: 4px 4px 0px rgba(19, 19, 16, 0.16);
	border-radius: var(--radius-xs);
	display: flex;
	flex-direction: column;
	align-items: center;

	h4 {
		font-family: $font_family_mono;
		font-weight: 600;

		@include md {
			font-weight: 700;
		}
	}
}

.loading_test {
	position: relative;
	display: block;
	width: $size;
	height: $size;
	border-radius: 12px;
	overflow: hidden;
}

.box {
	opacity: 0;
	animation: load 1s linear infinite;

	&:nth-of-type(1) {
		background: url("/images/loader/p_2.png") no-repeat;
		background-size: 100%;
		position: absolute;
		width: $size_p_2;
		height: $size_p_1;
		top: 0;
		left: 0;
		//outline: 1px solid red;
		animation-delay: calc(1s / -4 )*3;
	}

	&:nth-of-type(2) {
		background: url("/images/loader/p_3.png") no-repeat;
		background-size: 100%;
		position: absolute;
		width: $size_p_1;
		height: $size_p_2;
		top: 0;
		right: 0;
		//outline: 1px solid orange;
		animation-delay: calc(1s / -4 )*2;
	}

	&:nth-of-type(3) {
		background: url("/images/loader/p_4.png") no-repeat;
		background-size: 100%;
		width: $size_p_2;
		height: $size_p_1;
		position: absolute;
		bottom: 0;
		right: 0;
		animation-delay: calc(1s / -4 );
		//outline: 1px solid blue;
	}

	&:nth-of-type(4) {
		background: url("/images/loader/p_1.png") no-repeat;
		background-size: cover;
		width: $size_p_1;
		height: $size_p_2;
		position: absolute;
		bottom: 0;
		left: 0;

		//outline: 1px solid black;
	}
}

@keyframes load {
	0% {
		opacity: 0;
	}
	30% {
		opacity: 0;
	}
	90% {
		opacity: 1;
		//filter: blur(1);

	}
	100% {
		opacity: 0;
	}
}
