@import "src/styles/defs";

.card {
	padding: var(--x10);
	gap: var(--x14);
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	border-radius: var(--radius-m);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 250px;
}

.img {
	max-height: 260px;
	display: flex;
	justify-content: center;
	img {
		object-fit: contain;
	}
}

.disabled {
	opacity: 0.3;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		flex-basis: 215px;
		flex-grow: 1;
		@include md {
			flex-grow: 0;
		}
		@include lg {
			flex-grow: 1;
		}
	}
}

.stack {
	display: grid;
	align-items: flex-start;
	width: 100%;
	gap: var(--x6);
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	@include sm {
		gap: 18px 20px;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	}
}

.divider {
	width: 100%;
	height: 1px;
	background-color: #E2E2E2;
}

.shopCard {
	gap: var(--x6);
	@include sm {
		gap: var(--x8);
	}
}

.topper {
	color: var(--text-color-secondary);
	text-align: center;
}

.price {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: var(--x4);

	span {
		display: flex;
		align-items: center;
		gap: var(--x2);
		img {
			width: 24px;
			height: 24px;
		}
	}
}

.bottom {
	display: flex;
	flex-direction: column;
	gap: var(--x6);
}

.nftCard {
	padding: var(--x6) var(--x10) var(--x12);
	border-radius: var(--radius-sm);
	gap: var(--x6);
	text-align: center;
	flex-basis: 256px;

	.name {
		color: var(--text-color-default);
		height: 48px;
		overflow: hidden;
	}
}

.puzzleCard {
	display: flex;
	gap: var(--x4);
	background: var(--sys-neutral-nt10);
	border-radius: var(--radius-xs);
	padding: var(--x6) var(--x7) var(--x12);
	flex-direction: column;
	align-items: center;
	position: relative;

	.count {
		position: absolute;
		background-color: var(--buttons-accent);
		border-radius: var(--radius-xxs);
		padding: var(--x3);
		color: white;
		right: 8px;
		top: 8px;
	}

	.link {
		width: 64px;
	}

	img {
		height: 120px;
	}
}
