@import "src/styles/defs";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	@include lg {
		display: flex;
		align-items: center;
		flex-direction: row;
	}

	& > div {
		flex: 1 1 50%;
	}

	h2 {
		white-space: pre-wrap;
	}
}
