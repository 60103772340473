@import "src/styles/defs";

section.container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	gap: var(--x10);
}

.inner {
	display: flex;
	align-items: center;
	width: 100vw;
	max-width: 980px;

	@include lg {
		width: 100%;
	}
	:global(.swiper-wrapper) {
		padding: var(--x24) 0;
		@include lg {
			padding: var(--x24) 0 var(--x28);
		}
	}
}

.swiper {
	display: flex;
	flex-direction: column;
	width: 100%;

	:global(.swiper-slide-active) {
		opacity: 1;
		scale: 1.11;
	}

	:global(.swiper-pagination) {
		display: flex;
		gap: var(--x3);
		align-items: center;
		justify-content: center;
		position: relative;

		button.button {
			letter-spacing: 0.002em;
			transition: all .2s linear;
			cursor: pointer;
			user-select: none;
			width: 12px;
			height: 12px;
			background: var(--sys-primary-pr30);
			border-radius: var(--radius-m);
			padding: 0;
			margin: 0;
			opacity: 1;

			&:global(.swiper-pagination-bullet-active) {
				background: var(--bg-color-accent);
				width: 24px;
			}
		}
	}
}

div.swiperSlide {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 250px;
	height: 370px;
	opacity: 0.6;
	transition: all .3s linear;
	@include sm {
		width: 250px;
	}
	@include md {
		width: 300px;
		height: 460px;
	}

	img {
		transition: all .3s linear;
		position: absolute;
		left: 0;
		top: 0;
		max-width: 100%;
		max-height: 100%;
	}

}

.arrow {
	background: transparent;
	color: var(--text-color-accent);

	&:hover {
		background: transparent;
		color: var(--text-color-accent);
	}
}

.nextBtn, .prevBtn {
	display: none;
	width: 60px;
	height: 60px;
	letter-spacing: 0.002em;
	transition: all .2s linear;
	cursor: pointer;
	user-select: none;
	color: var(--bg-color-accent);
	background: var(--buttons-secondary);
	border: 2px solid var(--bg-color-accent);
	border-radius: var(--radius-xl);
	flex-shrink: 0;

	&:hover {
		background: var(--bg-color-accent);
		color: #FFFFFF;
	}

	@include xlg {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}