.container {
	display: flex;

	div {
		padding: 5px;
		margin: 3px;
		background: grey;
		max-height: 100px;
		overflow: hidden;
	}

	.selected {
		background: green;
	}
}

.selectedTemplates {
	.asset {
		.body {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		display: inline-block;
		vertical-align: top;
		padding: 5px;
		margin: 2px;
		background-color: rgba(0, 128, 0, 0.2);

		img, video {
			width: 50px;
		}
	}
}

.schema_box {
	border: 1px solid black;

	.format_value_power {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
