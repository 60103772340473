@import "src/styles/defs";

.tabs {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  ul {
    list-style-type: none;
    display: flex;
  }
}

.tabList {
  gap: var(--x4);
  padding: var(--x4);
  border-radius: var(--radius-sm);
  width: 100%;
  margin-bottom: 8px;
}

.tab {
  width: 100%;
  background-color: var(--bg-color-default);
  border-radius: var(--radius-sm);
  padding: var(--x3) var(--x6);
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: 2px solid transparent;

  p {
    color: var(--text-color-secondary-light);
  }

  &:hover {
    border: 2px solid var(--sys-neutral-nt30);
    color: var(--text-color-secondary-light);
  }
}


.tabPanel {
  width: 100%;
}

.selectedTab {
  border: 2px solid var(--sys-neutral-nt30);
  background-color: var(--bg-color-default);

  p {
    color: var(--text-color-default);
  }

  &:focus-visible {
    border: 2px solid var(--sys-neutral-nt30);
  }
}

.selectedTabPanel {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @include md {
    min-height: 351px;
  }
}