@import "src/styles/defs";

.h2 {
	font-weight: 700;
	font-size: 28px;
	line-height: 30px;
	letter-spacing: 0.002em;
	font-family: $font_family_secondary;
	color: var(--text-color-default);
	margin: 0;

	@include md {
		font-size: 40px;
		line-height: 52px;
	}

	@include lg {
		font-size: 54px;
		line-height: 60px;
	}
}