.schema_box {

}

.hidden {

}

.input {
	.value {
		display: none;
	}

	input[type="number"] {
		//background-color: grey;
		//pointer-events: none;
	}

	&:has(input:checked) {
		.value {
			display: inline-block;
		}

		input[type="number"] {
			//display: inline-block;
			//background-color: white;
			//pointer-events: auto;
		}
	}
}
