@import "src/styles/defs";

.container {
	display: flex;
	background: var(--bg-color-default);
	border-radius: var(--radius-xs);
	gap: var(--x6);
	padding: var(--x8) var(--x6);

	@include md {
		gap: var(--x8);
		padding: var(--x9) var(--x9) var(--x14);
		flex-direction: column;
	}

	@include lg {
		gap: var(--x12);
		padding: var(--x15) var(--x15) var(--x20);
	}
}

.img {
	width: 45%;
	display: flex;
	border-radius: var(--radius-sm);
	overflow: hidden;
	height: 60px;

	img {
		object-fit: cover;
	}

	@include md {
		height: 72px;
		width: 100%;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 55%;
	gap: var(--x);
	flex-grow: 1;
	justify-content: space-between;

	@include md {
		width: 100%;
		gap: var(--x6);
		align-items: center;
		text-align: center;
	}

	@include lg {
		gap: var(--x8);
	}
}
