@import "src/styles/defs";

.container {
}

.link, .activeLink {
	padding: 5px;
}

.activeLink {
	color: red;
}
