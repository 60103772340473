@import "src/styles/defs";

.container {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	padding-top: var(--x15);
	padding-bottom: var(--x2);

	@include sm {
		padding-bottom: var(--x15);
	}

	@include lg {
		display: flex;
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-width: 100%;
	gap: var(--x24);

	p {
		color: var(--text-color-disabled);
	}

	@include sm {
		flex-direction: row;
		align-items: center;
	}
}

.info {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: var(--x24);
	min-width: 100%;
	justify-content: space-between;

	@include sm {
		flex-direction: row;
		min-width: initial;
		justify-content: initial;
	}
}