@import "src/styles/defs";

.heading {
	display: flex;
	flex-direction: column;
	gap: var(--x4);
}

.expo {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	min-width: 100%;
	min-height: 100%;
	position: relative;
	padding: var(--x10) var(--x10) var(--x15);
	text-align: center;
}

.expo_card {
	transition: all .3s linear;
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	max-width: 260px;
	width: 100%;
	bottom: 0;
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	border-radius: var(--radius-sm);
	padding: var(--x6) var(--x12) var(--x12);
	gap: var(--x9);
	align-self: flex-end;
	justify-self: center;

	@include lg {
		padding: var(--x6) var(--x15) var(--x15);
	}
}

.disabled {
	img {
		opacity: 0.6;
	}
}