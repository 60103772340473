@import "../../styles/defs";

.help_view {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(201, 192, 201, 0.4);
	backdrop-filter: blur(2px);
	overflow: hidden;
	z-index: 1000;

	img {
		height: auto;
		border-radius: var(--radius-sm);
	}
	& > div:nth-child(1)
	{
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translate(-50%, -50%);
		height: auto;
		background-color: var(--bg-color-default);
		border-radius: var(--radius-m);
		border: 1px solid var(--border-secondary);
		padding: var(--x6);
	}
}