@import "src/styles/defs";
@import "src/styles/_variables.css";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.heading {
	max-width: 576px;
	margin: auto;
	@include lg {
		max-width: initial;
	}
}

div.cards {
	display: flex;
	flex-wrap: wrap;

	& > div:first-of-type {
		min-width: 100%;
		@include lg {
			min-width: initial;
		}
	}

	& > div {
		flex: 1 1 48%;
		min-height: 400px;
		@include md {
			min-height: 450px;
		}
		@include lg {
			flex: 1 1 50%;
		}
	}

	@include lg {
		flex-wrap: nowrap;
	}
}

