@import "src/styles/defs";

.card {
	padding: var(--x10) var(--x10) var(--x15);
	background: var(--bg-color-default);
	border-radius: var(--radius-xs);
	display: flex;
	flex-direction: column;
	gap: var(--x9);
	text-align: center;
	align-items: center;

	@include lg {
		align-items: flex-start;
		text-align: left;
		padding: var(--x15) var(--x15) var(--x20);
	}
}

.inner {
	display: flex;
	flex-direction: column;
	gap: var(--x6);
	flex-grow: 1;
	@include lg {
		gap: var(--x7);
	}
}

.img {
	display: flex;
	height: 120px;
	flex-shrink: 0;
	border-radius: var(--radius-xxs);
	overflow: hidden;
	width: 100%;

	img {
		object-fit: cover;
	}
}

.content {
	max-width: 100%;
	flex-grow: 1;
}

.stack {
	display: grid;
	width: 100%;
	gap: var(--x10);
	grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
}

.footer {
	margin-top: var(--x3);
	@include lg {
		margin-top: var(--x5);
	}
}