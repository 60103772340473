@import "src/styles/defs";

.button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	white-space: nowrap;
	border: none;
	cursor: pointer;
	font-weight: 600;
	box-sizing: border-box;
	font-family: $font_family_default;
	text-decoration: none;

	&:focus {
		outline: none;
	}

	&.with_icon {
		flex-shrink: 0;
		padding: var(--x5);
		background-color: var(--buttons-secondary);
		color: var(--text-color-default);
		border: 2px solid var(--border-buttons);
	}
}

.medium {
	font-size: 14px;
	line-height: 16px;

	svg {
		width: 24px;
		height: 24px;
	}

	&.min_width {
		min-width: 140px;
	}

	@include md {
		font-size: 16px;
		line-height: 24px;

		svg {
			width: 32px;
			height: 32px;
		}
	}

	&.icon_center {
		padding: var(--x3);

		@include md {
			padding: var(--x6);
		}
	}

	@include landscape {
		font-size: 14px;
		line-height: 16px;
		svg {
			width: 24px;
			height: 24px;
		}
	}
}

.small {
	font-size: 14px;
	line-height: 16px;

	svg {
		width: 24px;
		height: 24px;
	}

	&.min_width {
		min-width: 86px;
	}

	&.icon_center {
		padding: var(--x4);
	}
}

.secondary {
	background-color: var(--buttons-secondary);
	color: var(--text-color-default);
	border: 2px solid var(--border-buttons);
	font-weight: bold;
	padding: var(--x7) var(--x16);
	transition: box-shadow .2s linear;
	border-radius: var(--radius-xs);

	&:hover, &:active {
		&:disabled {
			box-shadow: none;
		}

		box-shadow: var(--shadow-blue)
	}

	&:disabled {
		color: var(--text-color-disabled);
		border-color: var(--border-secondary);
	}
}

.primary {
	background: var(--buttons-accent);
	border-radius: var(--radius-xs);
	color: #fff;
	font-weight: bold;
	padding: var(--x7) var(--x16);
	transition: box-shadow .2s linear;
	border: 2px solid var(--border-buttons);

	&:hover, &:active {
		&:disabled {
			box-shadow: none;
		}

		box-shadow: var(--shadow-blue)
	}

	&:disabled {
		color: var(--text-color-disabled);
		background-color: var(--buttons-disabled);
	}
}

.label {
	border-radius: var(--radius-xxs);
	background-color: var(--bg-color-accent);
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	color: #fff;
	padding: var(--x3) var(--x8);
	position: absolute;
	z-index: 1;
	bottom: 0;
	transform: translateY(70%) rotate(4deg);

	@include md {
		font-size: 12px;
		line-height: 14px;
		padding: var(--x3) var(--x8);
		right: 0;
		top: 0;
		bottom: initial;
		transform: translate(30%, -40%) rotate(4deg);

	}
}

.active {
	box-shadow: var(--shadow-blue)
}