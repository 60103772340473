.container {
	display: flex;
	flex-direction: column;
	padding: var(--x10);
	gap: var(--x12);
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	border-radius: var(--radius-m);
	text-align: center;
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
}

.buttons {
	display: flex;
	align-items: center;
	gap: var(--x4);

	& > a, button {
		flex: 1 1 50%;
	}
}

.heading {
	display: flex;
	flex-direction: column;
	gap: var(--x6);
}