@import "defs";

body {
	margin: 0;
	padding: 0;
	color: $text_color_default;
	font-family: $font_family_default;
	//overflow: hidden;
}

body * {
	box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
	overflow: hidden;
}

ul {
	margin: 0;
	padding: 0;
}

ul {
	padding: 0;
}

li {
	list-style: none;
}

a {
	color: $text_color_default;
}

p {
	font-size: 18px;
	line-height: 24px;

	@include md {
		font-size: 22px;
		line-height: 30px;
	}
}

img {
	user-select: none;
	width: 100%;
	height: auto;
}

:focus {
	outline: none;
}

button {
	color: $text_color_default;
	font-family: $font_family_default;
}

h1, h2, h3, h4, h5 {
	margin: 0 0 10px;
}

.animated.fadeIn {
	animation-duration: 2000ms !important;
	animation-delay: 300ms !important;
}
