@import "src/styles/defs";

.card {
	padding: var(--x10) var(--x8);
	gap: var(--x6);
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	border-radius: var(--radius-sm);
	display: flex;
	flex-direction: column;
	text-align: left;
	flex-basis: 100%;
	flex-grow: 0;

	@include lg {
		flex-basis: 290px;
		flex-grow: 1;
		max-width: 380px;
	}
}

.panting_card {
	flex-basis: 290px;
	flex-grow: 1;
	max-width: 502px;

	.img {
		max-height: 100%;

		@include sm {
			max-height: 366px;
		}
	}
}

.img {
	max-height: 212px;
	border-radius: var(--radius-sm);
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		height: auto;
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: var(--x7);
}

.text {
	display: flex;
	flex-direction: column;
	gap: var(--x3);
}

.amount {
	display: flex;
	align-items: center;
	gap: var(--x2);
	border: 1px solid var(--border-secondary);
	border-radius: var(--radius-xs);
	padding: var(--x4);
}