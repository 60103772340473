@import "src/styles/defs";

.container {
	padding: 20px;
}

header {
	a {
		padding: 5px;
	}
}
