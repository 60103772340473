@import "src/styles/defs";
@import "src/styles/_variables.css";

.header {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100vw;
	transition: background-color .3s linear;

	& > div {
		min-width: 100%;
	}

	@include lg {
		background-color: transparent;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: block;
		background-color: var(--bg-color-default);
		z-index: 20;
		@include lg {
			display: none;
		}
	}
}

.bg {
	background-color: var(--bg-color-default);
}

section.inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: var(--x14);
	padding: 12px;
	background-color: var(--bg-color-default);

	@include md {
		padding: var(--x8) var(--x15);
	}
	@include lg {
		background-color: transparent;
		padding: var(--x6) var(--x30);
	}
}

.navigation {
	position: absolute;
	z-index: -1;
	display: flex;
	flex-direction: column;
	left: 0;
	bottom: 0;
	width: 100%;
	gap: var(--x66);
	padding: var(--x20) var(--x20) var(--x28);
	transition: all .5s linear;
	background-color: var(--bg-color-default);

	@include lg {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		flex-grow: 1;
		gap: 0;
		padding: 0;
		transition: none;
		background-color: transparent;
	}
}

.navigation_inner {
	display: flex;
	gap: var(--x16);
	flex-direction: column;
}

.opened {
	opacity: 1;
	transform: translateY(100%);
}

.hamburger {
	display: flex;
	border: none;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	position: relative;
	z-index: 30;

	@include lg {
		display: none;
	}
}

.menu {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: var(--x6);

	@include lg {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: flex-end;
		gap: var(--x10);
	}
}

.link {
	color: #181818;
	font-size: 40px;
	line-height: 52px;
	margin: 0;
	font-weight: bold;
	font-family: $font_family_default;
	text-decoration: none;

	@include lg {
		font-size: 16px;
		line-height: 24px;
		color: var(--text-color-secondary-light);
		padding: var(--x3);
		font-weight: 400;
	}
}

.logo {
	display: flex;
	position: relative;
	z-index: 30;
	flex: 1 1 50%;
}

.socials {
	justify-content: center;
	@include lg {
		justify-content: initial;
	}
}

.stakeBtn {
	display: flex;
	align-items: center;

	& > button {
		width: 100%;
	}

	@include lg {
		display: none;
	}
}