@import "src/styles/defs";

.banner {
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 100%;
		position: absolute;
		left: 0;
		top: -84px;
		bottom: 0;
		background-image: url("/images/noise.png");
		z-index: -1;
		background-color: var(--bg-color-default);
	}
}

section.inner {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: var(--x30);
	padding: var(--x28) var(--x6) var(--x16);

	@include md {
		padding: var(--x32) var(--x15);
	}

	@include lg {
		flex-direction: row;
		gap: var(--x10);
		padding: var(--x28) var(--x30) var(--x40);
	}
}

.info {
	display: flex;
	flex-wrap: wrap;
	gap: var(--x20);

	@include lg {
		width: 50%;
		gap: var(--x16);
	}
}

.heading {
	display: flex;
	flex-wrap: wrap;
	gap: var(--x8);

	@include lg {
		gap: var(--x12);
	}

	h1 {
		display: inline-flex;
		align-items: center;
		flex-wrap: wrap;
		column-gap: var(--x4);
	}

	span {
		display: inline-flex;
		align-items: center;
		flex-shrink: 0;
		letter-spacing: normal;
		column-gap: var(--x4);
	}

	span:first-of-type {
		svg {
			color: var(--sys-tertiary-tr70);
		}
	}

	span:nth-child(2) {
		svg {
			color: var(--bg-color-accent);
		}
	}

	span:nth-child(3) {
		svg {
			color: var(--bg-color-accent);
		}
	}

	span:last-of-type {
		svg {
			color: var(--sys-secondary-sc70);
		}
	}

	h4 {
		font-weight: 400;
	}
}

.img {
	display: flex;
	flex-shrink: 0;
	@include lg {
		width: 50%;
	}
}

.buttons {
	display: flex;
	justify-content: center;
	gap: var(--x8);
	flex-direction: column;
	min-width: 100%;

	& > button {
		min-width: 100%;
	}

	@include lg {
		align-items: center;
		min-width: initial;
		flex-direction: row;
		gap: var(--x10);
		& > button {
			min-width: initial;
		}
	}

	@include md {
		justify-content: stretch;
		flex-direction: row;
		& > button {
			flex-grow: 1;
		}
	}
}

.subtitle {
	color: var(--text-color-secondary-light);
}