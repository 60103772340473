@import '../../styles/defs';

.container {
	position: fixed;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(201, 192, 201, 0.2);
	backdrop-filter: blur(2px);
	z-index: 1;
	padding: var(--x3);
	top: 0;
	left: 0;

	@include sm {
		padding: var(--x10);
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--x9);
	background-color: var(--bg-color-default);
	border-radius: var(--radius-m);
	box-shadow: var(--shadow-gametools);
	padding: var(--x10) var(--x8) var(--x12) var(--x8);
	max-width: 800px;
	width: 100%;
	border: 1px solid var(--sys-neutral-nt30);
	position: relative;
	text-align: center;

	@include sm {
		padding: var(--x12) var(--x12) var(--x16) var(--x12);
		gap: var(--x12);
	}
}

.title {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 48px;

	@include sm {
		height: 56px;
		justify-content: center;
	}
}

.close {
	position: absolute;
	right: 0;
	top: 0;
}

.prizeList {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--bg-color-white);
	border-radius: var(--radius-xs);
	padding: var(--x6);
	width: 100%;
	border: 1px solid var(--sys-neutral-nt30);
	text-align: center;
}