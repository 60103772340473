@import "src/styles/defs";

.accordion {
	width: 100%;
	transition: all .3s linear;
	overflow: hidden;
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
}

.header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	z-index: 1;
	padding: var(--x9) var(--x10);
	gap: var(--x9);
	background-color: var(--buttons-secondary);

	@include md {
		padding: var(--x12) var(--x16);
	}

	h4 {
		font-weight: 400;
		text-align: left;
	}
}

.icon {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--text-global-secondary);

	svg:last-of-type {
		position: absolute;
		left: 0;
		top: 0;
		transform: rotate(270deg);
		transition: transform .3s linear;
	}
}

.active {
	svg:last-of-type {
		transform: rotate(360deg);
	}
}

.content {
	padding: var(--x9) var(--x10);
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.002em;
	color: var(--text-color-secondary);
	text-align: left;
	@include md {
		padding: var(--x12) var(--x16);
		font-size: 16px;
		line-height: 24px;
	}
}
