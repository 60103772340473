@import "src/styles/defs";

.notifications-component {
	max-width: $header-max-width;

	.notification-container--top-right {
		display: flex;
		flex-direction: column;
		gap: 40px;
		right: 20px;
		top: 120px;

		.notification {
			transition-duration: 1ms !important;

			.notification__item {
				margin: 0;
			}

			.animate__animated {
				position: relative;
				border: none;
				border-radius: var(--radius-xs);
				padding: var(--x6);
				box-shadow: var(--shadow-gametools);

				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0.002em;

				.notification__content {
					max-width: 100%;

					.notification__message {
						color: var(--text-color-secondary);
					}
				}
			}

			.notification__item--success {
				background-color: var(--bg-color-success);

				.notification__timer {
					background-color: var(--bg-color-success);

					.notification__timer-filler {
						background-color: var(--border-success);
					}
				}
			}

			.notification__item--danger {
				background-color: var(--sys-tertiary-tr10);

				.notification__timer {
					background-color: var(--sys-tertiary-tr10);

					.notification__timer-filler {
						background-color: var(--border-error);
					}
				}
			}
		}
	}
}