@import "src/styles/defs";

.container {
	position: relative;
	white-space: nowrap;

	&:hover {
		.tooltip {
			display: flex;
		}
	}
}

.tooltip {
	display: none;
	position: absolute;
	z-index: 10;
	left: 50%;
	top: -13px;
	box-shadow: 4px 4px 0px rgba(19, 19, 16, 0.16);
	border-radius: var(--radius-xs);
	transform: translate(-50%, -100%);
	max-width: 200px;

	p {
		color: inherit;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&:before {
		content: "";
		position: absolute;
		bottom: -8px;
		left: 50%;
		transform: translateX(-50%) rotate(45deg);
		width: 16px;
		height: 16px;
		background: var(--bg-color-default);
		z-index: 1;
		box-shadow: 4px 0px 0px rgba(19, 19, 16, 0.16);
		border-bottom-right-radius: 2px;
	}

	&:after {
		position: absolute;
		content: "";
		bottom: -11px;
		left: calc(50% + 4px);
		transform: translateX(-56%) rotate(45deg);
		width: 16px;
		height: 16px;
		background: var(--bg-color-default);
		border-bottom-right-radius: 4px;
	}

}

.content {
	position: relative;
	z-index: 1;
	display: inline-flex;
	padding: var(--x4);
	background: var(--bg-color-default);
	border-radius: var(--radius-xs);
	overflow: hidden;
	height: 100%;
	width: 100%;
	@include md {
		padding: var(--x6);
	}
}