.container {
	position: relative;
	display: inline-flex;
	align-items: center;
	gap: var(--x);

	label {
		cursor: pointer;
		user-select: none;
	}

	input {
		position: absolute;
		left: 0;
		top: 0;
		width: 30px;
		height: 100%;
		display: flex;
		margin: 0;
		opacity: 0;
		cursor: pointer;
		z-index: 99;
	}

	input:checked + .inner {
		background: var(--bg-color-accent);
		.circle {
			left: initial;
			right: 4px;
		}
	}
}

.inner {
	position: relative;
	display: flex;
	width: 30px;
	background: var(--border-secondary);
	border-radius: var(--radius-m);
	height: 20px;
	cursor: pointer;

	.circle {
		position: absolute;
		left: 4px;
		top: 50%;
		transform: translateY(-50%);
		width: 12px;
		height: 12px;
		display: flex;
		background-color: #FCFCFC;
		border-radius: 100%;
	}
}