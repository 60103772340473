@import "src/styles/defs";

.textButton {
	background: $surface;
	position: relative;
	font-size: 24px;
	color: $color_button;
	min-width: 116px;
	height: 32px;
	border-top: 3px outset white;
	border-left: 3px outset white;
	border-right: 3px outset rgb(211 200 200 / 70%);
	border-bottom: 3px outset rgb(211 200 200 / 70%);
	box-shadow: 2px 2px 0 0 black;
	text-transform: capitalize;
	cursor: pointer;


	&:active {
		box-shadow: inset 2px 2px 0 0 black;
		border-bottom: 3px inset white;
		border-right: 3px inset white;
		border-left: 3px inset rgb(211 200 200 / 70%);
		border-top: 3px inset rgb(211 200 200 / 70%);
	}
}

.script_button {
	background: $surface;
	position: relative;
	font-size: 14px;
	color: $color_button;
	width: 42px;
	height: 26px;
	border-top: 3px outset white;
	border-left: 3px outset white;
	border-right: 3px outset rgb(211 200 200 / 70%);
	border-bottom: 3px outset rgb(211 200 200 / 70%);
	box-shadow: 2px 2px 0 0 black;
	text-transform: capitalize;
	cursor: pointer;


	&:active {
		box-shadow: inset 2px 2px 0 0 black;
		border-bottom: 3px inset white;
		border-right: 3px inset white;
		border-left: 3px inset rgb(211 200 200 / 70%);
		border-top: 3px inset rgb(211 200 200 / 70%);
	}
}


button:disabled {
	border-color: transparent;
	color: grey;
	box-shadow: none;
	cursor: auto;
	pointer-events: none;
}

button.busy {
	box-shadow: inset 2px 2px 0 0 black;
	border-bottom: 3px inset white;
	border-right: 3px inset white;
	border-left: 3px inset rgb(211 200 200 / 70%);
	border-top: 3px inset rgb(211 200 200 / 70%);
	cursor: auto;
}
