.container {
	display: flex;
	justify-content: center;
	align-items: center;

	div {
		border: 1px solid;
		margin: 10px;
		padding: 10px;
	}

	.active {
		background-color: darkolivegreen;
		color: white;
	}

	.inactive {
		background-color: #8a8a8a;
	}
}