@import "src/styles/defs";

.header {
	position: fixed;
	left: 50%;
	transform: translate(-50%);
	top: var(--x3);
	width: 100%;
	max-width: calc(100% - var(--x6));
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	box-shadow: 4px 4px 0 rgba(19, 19, 16, 0.16);
	border-radius: var(--radius-sm);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: var(--x6);
	z-index: 10;

	@include md {
		padding: var(--x8);
		max-width: calc(100% - var(--x10));
	}

	@include lg {
		width: calc(100% - var(--x20));
		max-width: $header-max-width;
	}
}

.logo, .auth {
	display: flex;
	align-items: center;
	@include md {
		flex-basis: 210px;
	}
}

.logo {

	a {
		display: flex;
		padding: 0;
	}

	img {
		height: 48px;
		width: 48px;

		@include md {
			height: 56px;
			width: 98px;
		}
	}
}

.auth {
	justify-content: flex-end;
}