@import "src/styles/defs";

.paginator_container {
	display: flex;

	input, select {
		padding: 7px;
		margin: 0 10px;
		color: black;
	}

	.page, .page_current, .page_nav {
		padding: 6px 10px;
		border: 1px solid black;
		margin: 0 2px;
		user-select: none;
		border-radius: 4px;
	}

	.page_nav {
		cursor: pointer;

		&:hover {
			background-color: grey;
		}
	}

	.page_current {
		background-color: green;
	}

	button {
		margin: 0 2px;
	}
}
