.images {
	overflow: auto;

	div {
		display: inline-block;
		vertical-align: top;
		position: relative;
	}

	img {
		height: 200px;
		width: auto;
		margin: 10px;
		border: 1px solid black;
	}

	.size {
		position: absolute;
		bottom: 16px;
		left: 10px;
		width: calc(100% - 20px);
		text-align: center;
		background-color: rgba(255, 255, 255, 0.88);
		height: 16px;
		font-size: 14px;
		line-height: 16px;

		input {
			width: 50px;
		}
	}
}

.tools {
	input, button {
		width: 50px;
	}
}

.canvas_container {
	overflow: auto;
}