@import "src/styles/defs";

.wrapper {
	background: rgba(201, 192, 201, 0.4);
	backdrop-filter: blur(2px);
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--x3);
}

.container {
	display: flex;
	flex-direction: column;
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	box-shadow: 4px 4px 0px rgba(19, 19, 16, 0.16);
	border-radius: var(--radius-m);
	padding: var(--x10) var(--x8) var(--x12);
	gap: var(--x9);
	width: 100%;
	max-width: 680px;

	@include md {
		width: initial;
		gap: var(--x12);
		align-items: center;
		padding: var(--x12) var(--x12) var(--x16);
	}
}

.heading {
	display: flex;
	flex-direction: column;
	gap: var(--x9);
	text-align: center;
	@include md {
		gap: var(--x12);
	}

	h3 {
		padding: var(--x5) 0;
		@include md {
			padding: var(--x6) 0;
		}
	}
}

.prizes {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	gap: var(--x9);

	@include md {
		flex-direction: row;
		gap: var(--x12);
	}
}

.prize {
	display: flex;
	align-items: center;
	padding: var(--x6);
	gap: var(--x4);
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	border-radius: var(--radius-xs);
	flex-grow: 1;


	@include md {
		flex-direction: column;
		max-width: 300px;
		width: 100%;
		gap: var(--x6);
		padding: var(--x6) var(--x6) var(--x10);
	}
}

.img {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 120px;
	flex-shrink: 0;

	img {
		max-height: 120px;
		width: initial;
		@include md {
			max-height: 100%;
		}
	}

	@include md {
		width: 280px;
		height: 280px;
	}
}

.desc {
	display: flex;
	flex-direction: column;
	gap: var(--x3);
	flex-grow: 1;

	@include md {
		align-items: center;
		justify-content: center;
	}

	span {
		display: flex;
		align-items: center;
		gap: var(--x2);
	}

	p {
		@include md {
			text-align: center;
		}
	}
}

.naming {
	display: flex;
	flex-direction: column;
	gap: var(--x6);
	@include md {
		align-items: center;
		justify-content: center;
		gap: var(--x4);
	}
}
