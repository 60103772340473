@import "src/styles/defs";

.menu {
	position: relative;
	display: flex;
	border: none;
	background-color: transparent;
	padding: 0;
	margin: 0;

	&:before {
		position: absolute;
		bottom: -12px;
		left: 0;
		width: 100%;
		content: "";
		display: block;
		height: 12px;
		background-color: transparent;
	}

	@include md {
		max-width: 210px;
		width: 100%;
	}
}

.header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	z-index: 1;
	padding: var(--x6) var(--x5) var(--x6) var(--x8);
	gap: var(--x);
	background: var(--bg-color-default);
	border: 2px solid var(--text-color-default);
	border-radius: var(--radius-sm);
	width: 100%;

	p {
		color: var(--text-color-default)
	}
}

.icon {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--text-global-secondary);
}

.content {
	position: absolute;
	bottom: -12px;
	right: 0;
	width: 100vw;
	transform: translateY(100%);
	border-radius: 8px;
	overflow: hidden;
	max-width: 210px;
}

.inner {
	padding: 12px 16px 16px;
	gap: 18px;
	background: #FBFAF4;
	border: 1px solid #C9C0C9;
	box-shadow: 4px 4px 0px rgba(19, 19, 16, 0.16);
	border-radius: 8px;
}
