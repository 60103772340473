@import "src/styles/defs";

.container {
	position: fixed;
	bottom: 20px;
	left: 12px;
	z-index: 1001;
	display: flex;
	align-items: center;
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	box-shadow: var(--shadow-gametools);
	border-radius: var(--radius-sm);
	padding: var(--x8);
	gap: var(--x6);

	@include md {
		left: 24px;
		top: 24px;
		bottom: initial;
		flex-direction: column;
		padding: var(--x10);
		gap: var(--x10);
	}

	@include landscape{
		left: 12px;
		top: 12px;
	}
}

.button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	padding: 0;
	cursor: pointer;
	background-color: transparent;
	color: var(--text-color-default);
	transition: color .1s linear;
	width: 24px;
	height: 24px;

	@include md {
		width: 32px;
		height: 32px;
	}

	&:hover {
		color: var(--bg-color-accent);
	}
}

.handler {
	transform: rotate(270deg);
	&.opened {
		transform: rotate(90deg);
	}
	@include md {
		transform: rotate(0);
		&.opened {
			transform: rotate(180deg);
		}
	}
}


@media (hover: none) and (pointer: coarse) {
	.button {
		&:hover {
			color: inherit;
		}

		&:active {
			color: var(--text-color-accent);
		}
	}
}
