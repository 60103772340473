@import "src/styles/defs";

.buttonlink {
	position: relative;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	color: var(--text-color-default);
	gap: var(--x);
	letter-spacing: 0.002em;
	transition: all .2s linear;
	font-size: 14px;
	line-height: 20px;

	&:before {
		content: '';
		position: absolute;
		display: block;
		left: 0;
		bottom: -2px;
		width: 100%;
		height: 1px;
		transition: all .2s linear;
		background-color: var(--text-color-default);
	}

	&:hover, &:active {
		color: var(--text-color-link);

		&:before {
			background-color: var(--text-color-link);
		}
	}
}

.medium {
	@include lg {
		font-size: 16px;
		line-height: 24px;
	}
}

.reverse {
	flex-direction: row-reverse;
}