@import "../../styles/defs";

.container {
	background: var(--bg-color-default) url("/images/canvas_ptrn.png");
	background-size: 45px;
	display: flex;
	justify-content: center;
	width: 100%;
	overflow: auto;
	height: 100%;
}

.content {
	display: flex;
	flex-direction: column;
	max-width: $header-max-width;
	width: 100%;
	gap: var(--x16);
	margin-top: var(--x10);

	padding: 0 var(--x4);

	@include sm {
		padding: 0 var(--x10);
	}
}

.wrap {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: var(--x20);
	align-items: flex-start;
}

.tittle {
	display: flex;
	justify-content: center;
}

.row {
	display: flex;
	gap: var(--x5);
}

.cellsWrap {
	background-color: var(--sys-neutral-nt10);
	border-radius: var(--radius-m);
	box-shadow: var(--shadow-gametools);
	border: 1px solid var(--sys-neutral-nt30);
	width: 100%;
	max-width: 794px;

	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-template-rows: repeat(8, 1fr);
	grid-column-gap: 4px;
	grid-row-gap: 4px;

	@include sm {
		grid-column-gap: 10px;
		grid-row-gap: 10px;
	}

	padding: var(--x6);
	@include md {
		padding: var(--x10);
	}

	@media (min-width: $header-max-width) {
		max-width: 574px;
	}
}

.controls {
	display: flex;
	flex-direction: column;
	gap: var(--x14);
	border-radius: var(--radius-m);
	background-color: var(--sys-neutral-nt10);
	max-width: 794px;
	width: 100%;
	border: 1px solid var(--sys-neutral-nt30);

	padding: var(--x9) var(--x6);
	@include sm {
		padding: var(--x10);
	}

	@media (min-width: $header-max-width) {
		max-width: 574px;
	}
}

.mobile {
	display: flex;
	justify-content: center;

	@media (min-width: $header-max-width) {
		display: none;
	}
}

.desktop {
	display: none;
	@media (min-width: $header-max-width) {
		display: block;
	}
}

.disabled, p {
	color: var(--text-color-disabled);
}

.wrapRow {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: var(--x3);
}

.price {
	color: var(--text-color-accent);
}

.tryWrap {
	display: flex;
	flex-direction: column;
	gap: var(--x8);
}

.waxBalance {
	display: flex;
	justify-content: center;
	gap: var(--x3);

	@include sm {
		justify-content: flex-end;
	}
}

.reward {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: var(--x10);
	background-color: var(--sys-neutral-nt10);
	border-radius: var(--radius-sm);
	box-shadow: var(--shadow-gametools);
	padding: var(--x8) var(--x10) var(--x10) var(--x10);
	border: 1px solid var(--sys-neutral-nt30);
}

.link {
	display: flex;
	align-self: center;
}