@import "../../styles/defs";

.list {
	display: flex;
	gap: var(--x10);
	flex-wrap: wrap;
	justify-content: center;

	@media (min-width: $header-max-width) {
		flex-wrap: wrap;
	}
}