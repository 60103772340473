@import "src/styles/defs";

.heading {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--x12);
	text-align: center;
	max-width: 1030px;

	&.left {
		text-align: center;
		@include lg {
			align-items: flex-start;
			text-align: left;
			max-width: 540px;
		}
	}
}

.title {
	color: var(--text-color-secondary-light);
	font-weight: 400;

	svg {
		width: 12px;
		height: 12px;
		color: var(--sys-tertiary-tr70);

	}
}

.text {
	max-width: 576px;

	a {
		margin: 0 var(--x);
		color: var(--text-color-link);
		text-decoration: none;
		position: relative;
	}

	a:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		display: block;
		background-color: var(--text-color-link);
	}
}
