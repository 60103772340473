.slider
{
	width: 100%;
	height: 40px;
	position: relative;
	margin-top: 55px;
	//background-color: red;
	//border-radius: 5px;
	z-index: 1;
	.back
	{
		position: absolute;
		height: 6px;
		border-radius: 5px;
		width: 100%;
		background-color: #a6bce1;

	}
	.affected
	{
		background-color: #337eff;
	}

}
.pointer
{
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 100px;
	transform: translate(-50%,-7px);
	background-color: white;
	border: 1px solid blue;
	box-shadow: 1px 1px 1px black;
	left: 0;
	top: 0;
	z-index: 2;
}
.label
{
	position: absolute;
	z-index: 10;
	left: 50%;
	top: -26px;
	box-shadow: 4px 4px 0px rgba(19, 19, 16, 0.16);
	border-radius: var(--radius-xs);
	transform: translate(-50%, -100%);
	max-width: 200px;
	padding: var(--x6);

	p {
		color: inherit;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&:before {
		content: "";
		position: absolute;
		bottom: -8px;
		left: 50%;
		transform: translateX(-50%) rotate(45deg);
		width: 16px;
		height: 16px;
		background: var(--bg-color-default);
		z-index: 1;
		box-shadow: 4px 0px 0px rgba(19, 19, 16, 0.16);
		border-bottom-right-radius: 2px;
	}

	&:after {
		position: absolute;
		content: "";
		bottom: -11px;
		left: calc(50% + 4px);
		transform: translateX(-56%) rotate(45deg);
		width: 16px;
		height: 16px;
		background: var(--bg-color-default);
		border-bottom-right-radius: 4px;
	}
}
.line
{
	position: absolute;
	height: 28px;
	border-left: 3px solid var(--sys-primary-pr90);
	top: -10px;
	transform: translateX(-50%);
	border-radius: 2px;
	z-index: 1;
}
.mark
{
	position: absolute;
	transform: translateX(-50%);
	//width: 27px;
	//border: 1px solid;
	color: grey;
	top: 20px;
	text-align: center;
	font-size: 12px;
	&:before
	{
		position: absolute;
		content: "";
		height: 4px;
		left: 50%;
		border-left: 1px solid;
		top: -5px
	}

	//margin-left: -20px;
}