@import "src/styles/defs";

.container {
	position: relative;
	white-space: nowrap;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		.tooltip {
			display: flex;
		}

		&:before {
			content: "";
			position: absolute;
			top: -20px;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			width: 12px;
			height: 12px;
			background: var(--sys-secondary-sc50);
			z-index: 2;
			box-shadow: 4px 0 0 rgba(19, 19, 16, 0.16);
			border-bottom-right-radius: 2px;

			@include sm {
				width: 16px;
				height: 16px;
			}
		}
	}
}

.tooltip {
	display: none;
	position: absolute;
	left: 50%;
	top: -13px;
	box-shadow: 4px 4px 0 rgba(19, 19, 16, 0.16);
	border-radius: var(--radius-xs);
	transform: translate(-50%, -100%);
	max-width: 200px;
	z-index: 1;

	p {
		color: inherit;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.content {
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: var(--x4);
	background: var(--sys-secondary-sc50);
	border-radius: var(--radius-xs);
	overflow: hidden;
	@include md {
		padding: var(--x6);
	}
}

.wrong {
	.content {
		background-color: var(--sys-tertiary-tr30);
	}

	&:before {
		background-color: var(--sys-tertiary-tr30) !important;
	}
}

.leftPopOver {
	.tooltip {
		left: 0;
		transform: translate(0, -100%);
	}
}

.rightPopOver {
	.tooltip {
		left: 100%;
		transform: translate(-100%, -100%);
	}
}