.container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;
}

.section {
	display: flex;
	align-items: center;
	gap: 20px;
}