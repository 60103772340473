@import "src/styles/defs";

.container {
	display: flex;
	align-items: center;
	text-align: left;
	gap: var(--x20);
	min-width: 100%;
	flex-direction: column;

	@include lg {
		flex-direction: row;
		gap: var(--x15);
	}
}

.reverse {

	@include lg {
		flex-direction: row-reverse;

	}

	.inner {
		padding-right: 0;
	}
}

.inner {
	max-width: 540px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: stretch;
	gap: var(--x14);

	@include lg {
		gap: var(--x20);
	}

	div.content {
		& > button {
			min-width: 100%;

			@include lg {
				min-width: initial;
			}
		}
	}
}

.heading {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: stretch;
	gap: var(--x6);
	@include lg {
		gap: var(--x12);
		padding-right: var(--x8);
		text-align: left;
		align-items: flex-start;
	}
}


.block {
	max-width: 576px;
	@include lg {
		width: 50%;
	}
}

.img {
	display: flex;
	align-items: center;
	justify-content: center;
}