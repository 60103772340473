.container {
	.staked_asset {
		display: inline-block;
		vertical-align: top;

		img, video {
			width: 100px;
		}
	}

	.prop {
		display: flex;

		div:first-child {
			font-weight: bold;
			margin-right: 10px;
		}
	}
}