.table {
	width: 100%;
	text-align: left;

	td, th {
		border-left: 1px solid black;
		border-right: 1px solid black;
		padding: 5px;
	}

	th {
		border-bottom: 1px solid black;
	}
}