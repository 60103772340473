@import "src/styles/defs";

.container {
	display: flex;
	flex-direction: column;
	gap: var(--x10);
	padding-left: var(--x6);
	padding-right: var(--x6);
	padding-bottom: var(--x6);
	padding-top: var(--x4);

	@include sm {
		margin-top: 0;
		gap: var(--x8);
		padding-left: var(--x8);
		padding-right: var(--x8);
		padding-top: var(--x8);
		padding-bottom: var(--x10);
	}
	@include md {
		margin-top: 0;
		gap: var(--x9);
		padding-left: var(--x10);
		padding-right: var(--x10);
		padding-top: var(--x7);
		padding-bottom: var(--x10);
	}
}

.list {
	display: grid;
	align-items: flex-start;
	width: 100%;
	gap: var(--x6);
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	@include sm {
		gap: 18px 20px;
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	}
}

.content {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: var(--x6);
}