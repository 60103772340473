.staking_container {
	display: flex;

	fieldset h1:last-child {
		margin: 0;
	}

	.side {
		width: 50%;
	}

	.properties_block {
		flex-grow: 1;

		p {
			margin: 0;
		}

		div {
			justify-content: space-between;
			align-items: baseline;
			border-bottom: 1px dotted grey;
			padding: 2px;
			margin-bottom: 2px;
			font-size: 18px;
			display: flex;

			div {
				border-bottom: none;
				margin-bottom: 0;
			}

			div:last-child {
				font-weight: bold;
				text-align: right;
			}
		}

		fieldset div:last-child {
			border: none;
		}
	}

	.actions_block {
		button {
			margin-right: 10px;
		}
	}

	.restake_block {
		//align-items: center !important;
		display: block !important;
		padding-bottom: 10px !important;

		div {
			margin-bottom: 10px !important;

			h3, h4 {
				margin: 0;
			}

		}

		button {
			margin-right: 10px;
		}
	}

	.input_block {
		display: flex;
		justify-content: space-between;
		align-items: center !important;

		input {
			width: 100%;
			padding: 6px;
			margin-right: 10px;
		}
	}
}
