@import "src/styles/defs";

.loader {
	color: #fff;
	width: 10%;
	height: 10%;
	border-radius: 50%;
	position: relative;
	text-indent: -9999em;
	animation: mulShdSpin 1.3s infinite linear;
	transform: translateZ(0);
	font-size: 20%;

	@include sm {
		font-size: 30%;
	}
	@include md {
		font-size: 50%;
	}
	@media (min-width: $header-max-width) {
		font-size: 30%;
	}
}

@keyframes mulShdSpin {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em,
		2em -2em 0 0em, 3em 0 0 -1em,
		2em 2em 0 -1em, 0 3em 0 -1em,
		-2em 2em 0 -1em, -3em 0 0 -1em,
		-2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em,
		3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
		-2em 2em 0 -1em, -3em 0 0 -1em,
		-2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em,
		2em -2em 0 0, 3em 0 0 0.2em,
		2em 2em 0 0, 0 3em 0 -1em,
		-2em 2em 0 -1em, -3em 0 0 -1em,
		-2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
		3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
		-2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
		3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
		-2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
		3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
		-2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em,
		3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
		-2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em,
		3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
		-2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
}
