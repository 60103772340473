@import "src/styles/defs";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.inner {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: var(--x24);
	min-width: 100%;
	@include lg {
		flex-direction: row;
		padding-top: var(--x20);
		gap: var(--x30);
		height: 600px;
	}
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiperSlide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.pagination {
	display: flex;
	flex-direction: column;
	gap: var(--x);
	padding: var(--x7);
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--x8) var(--x7);
	width: 64px;
	height: 64px;
	background: transparent;
	border-radius: 60px;
	border: none;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.002em;
	color: var(--text-color-disabled);
	transition: all .2s linear;
	cursor: pointer;
	user-select: none;

	&:hover {
		background: var(--bg-color-accent);
		color: #FFFFFF;
	}
}

.active {
	background: var(--bg-color-accent);
	color: #FFFFFF;
}

.arrow {
	background: transparent;
	color: var(--text-color-accent);

	&:hover {
		background: transparent;
		color: var(--text-color-accent);
	}
}