@import "src/styles/defs";

.container {
	position: fixed;
	right: 12px;
	top: 12px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 1001;

	@include md {
		top: 24px;
		right: 24px;
	}

}

.controllers {
	display: flex;
	align-items: center;
	gap: var(--x8);
}

.menu {
	position: absolute;
	bottom: -8px;
	right: 0;
	transform: translateY(100%);
	display: flex;
	flex-direction: column;
	padding: var(--x6) var(--x8) var(--x8);
	gap: var(--x6);
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	box-shadow: 4px 4px 0px rgba(19, 19, 16, 0.16);
	border-radius: var(--radius-sm);
	max-width: 240px;

	.buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: var(--x3);

		button {
			flex-grow: 1;
			min-width: 0;
		}
	}

	@include md {
		gap: var(--x9);
	}

	&:before {
		position: absolute;
		left: 0;
		top: -8px;
		width: 100%;
		height: 8px;
		background-color: transparent;
		display: block;
		content: '';
	}
}

.inner {
	display: flex;
	flex-direction: column;
	gap: var(--x4);

	@include md {
		gap: var(--x6);
	}
}

.block {
	display: flex;
	flex-direction: column;
	gap: var(--x3);

	@include md {
		gap: var(--x4);
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: var(--x2);
}

.headline {
	color: var(--text-color-secondary);
}

.actions {
	display: flex;
	flex-direction: column;
	gap: var(--x6);
	margin-top: 2px;
}

.item {
	display: flex;
	align-items: center;
	gap: var(--x2);

	& p {
		color: var(--text-color-default);
	}
}

.divider {
	display: block;
	width: 100%;
	height: 1px;
	background-color: var(--border-secondary);
}

@include landscape{
	.container {
		right: 12px;
		top: 12px;
	}
	.menu {
		max-width: initial;
		gap: var(--x6);
	}

	.inner {
		flex-direction: row;
		gap: var(--x4);
		.divider {
			width: 1px;
			display: block;
			min-height: 140px;
			background: var(--border-secondary);
			flex-shrink: 0;
		}

		.buttons {
			button {
				min-width: 90px;
				width: 90px;
			}
		}
	}

	.block {
		flex: 1 1 186px;
		min-width: 186px;
	}

	.actions {
		display: flex;
		flex-direction: column;
		gap: var(--x6);
	}
}