@import "src/styles/defs";

.footer {
	position: fixed;
	bottom: var(--x10);
	left: 50%;
	transform: translateX(-50%);
	padding: var(--x7);
	gap: var(--x10);
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	box-shadow: 4px 4px 0 rgba(19, 19, 16, 0.16);
	border-radius: var(--radius-sm);
	display: flex;
	max-width: calc(100% - var(--x6));
	z-index: 9999;
	color: var(--text-color-secondary);

	@include md {
		padding: var(--x8) var(--x10);
		max-width: calc(100% - var(--x10));
	}

	@include lg {
		max-width: calc(100% - var(--x20));
	}
}

.hidden {
	display: none;
}