@import "../../styles/defs";

.text {
	display: flex;
	align-items: center;
	gap: var(--x2);

	img {
		@include sm {
			width: 24px;
			height: 24px;
		}
		width: 18px;
		height: 18px;
	}
}