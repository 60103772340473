@import "src/styles/defs";

.tools_b {
	position: absolute;
	left: 20px;
	top: 20px;
}

.canvas_container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: var(--bg-color-default) url("/images/canvas_ptrn.png");
	background-size: 45px;
	//background: var(--bg-color-default) url("/front/src/images/canvas_ptrn.png");
	//background-size: 45px;

}

.movable {
	cursor: grab;
}

.timer {
	position: fixed;
	z-index: 1001;
	left: 12px;
	top: 12px;
	padding: var(--x6);
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	box-shadow: 4px 4px 0px rgba(19, 19, 16, 0.16);
	border-radius: var(--radius-sm);

	h4 {
		font-family: $font_family_mono;
		font-weight: 600;

		@include md {
			font-weight: 700;
		}
	}

	@include md {
		left: 50%;
		top: 24px;
		transform: translateX(-50%);
	}

	@include landscape{
		left: 50%;
		top: 12px;
	}
}

.button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	padding: 0;
	cursor: pointer;
	background-color: transparent;
	color: var(--text-color-default);
	transition: color .1s linear;
	width: 24px;
	height: 24px;

	input {
		position: absolute;
		left: 0;
		margin: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}

	@include md {
		width: 32px;
		height: 32px;
	}

	input:checked + svg {
		color: var(--text-color-accent);
	}

	&:hover {
		color: var(--text-color-accent);
	}
}

.divider {
	display: flex;
	width: 100%;
	height: 1px;
	background: var(--border-secondary);
}

// Todo: remove this when unneeded

nav.temp {
	bottom: 84px;
	@include md {
		left: 124px;
		bottom: initial;
	}
}


@media (hover: none) and (pointer: coarse) {
	.button {
		&:hover {
			color: inherit;
		}

		&:active {
			color: var(--text-color-accent);
		}
	}
}

.modal {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding: var(--x6) var(--x4);

	@include md {
		padding: 106px;
		align-items: center;
	}

	.backdrop {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background: rgba(201, 192, 201, 0.2);
		backdrop-filter: blur(2px);
		z-index: 2;
	}

	.inner {
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		z-index: 5;
		bottom: 84px;
		max-height: 100%;
		overflow: hidden;

		& > div {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			max-height: 100%;
			max-width: 100%;
			background: var(--bg-color-default);
			border: 1px solid var(--border-secondary);
			border-radius: var(--radius-m);
			padding: var(--x6);
			z-index: 5;
		}

		@include md {
			bottom: 0;
			align-items: center;
		}

		img {
			border-radius: var(--radius-sm);
			max-width: 100%;
			height: 100%;
			object-fit: contain;
			max-height: 570px;
		}
	}
}

.compose {
	position: fixed;
	bottom: 106px;
	left: 50%;
	transform: translate(-50%);

	@include md {
		width: 100%;
		max-width: 210px;
	}
}