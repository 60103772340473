@import "src/styles/defs";

.painting {
	display: flex;
	gap: var(--x6);
	background: var(--bg-color-default);
	border: 1px solid var(--border-secondary);
	border-radius: var(--radius-m);
	flex-direction: column;
	overflow: hidden;
	padding: var(--x10) var(--x8);

	@include sm {
		flex-direction: row;
	}

	@include lg {
		flex-direction: column;
		padding: var(--x10) var(--x8);
		flex: 1 1 33%
	}

	.img {
		display: flex;
		justify-content: center;
		max-height: 200px;
		height: 100%;
		width: 100%;
		max-width: 100%;
		border-radius: var(--radius-m);
		overflow: hidden;

		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
			object-position: top;
		}

		@include sm {
			max-width: 300px;
			max-height: 280px;
		}

		@include lg {
			max-height: 220px;
			max-width: 350px;
		}
	}
}

.description {
	display: flex;
	flex-direction: column;
	gap: var(--x6);
	flex-grow: 1;

	p {
		color: var(--text-color-default);
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: var(--x7);
	flex-grow: 1;

	p {
		color: var(--text-color-default);
	}
}



.heading {
	display: flex;
	flex-direction: column;
	gap: var(--x3);

	p:last-of-type {
		color: var(--text-color-secondary-light);
	}
}

.details {
	display: flex;
	flex-direction: column;
	gap: var(--x2);
	margin-top: auto;

	& > div {
		display: flex;
		align-items: center;
		gap: var(--x2);
	}
}

.bottom {
	display: flex;
	justify-content: space-between;
	padding: var(--x2) var(--x3);
	margin-top: auto;


	& > div {
		display: flex;
		align-items: center;
		gap: var(--x2);

		p {
			color: var(--text-color-default);
		}
	}
}

.buttons {
	display: flex;
	align-items: center;
	gap: var(--x3);

	& span {
		display: flex;
		flex-grow: 1;
	}

	.deposit {
		flex-grow: 1;
	}
}

.divider {
	display: block;
	width: 100%;
	height: 1px;
	background-color: var(--border-secondary);
}

.cards {
	display: flex;
	flex-direction: column;
	max-width: 1180px;
	width: 100%;
	margin: 0 auto;
	gap: var(--x10);

	@include lg {
		flex-direction: row;
	}
}