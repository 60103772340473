@import "src/styles/defs";

.h1 {
	font-weight: 700;
	font-size: 48px;
	line-height: 56px;
	letter-spacing: 0.002em;
	font-family: $font_family_secondary;
	color: var(--text-color-default);
	margin: 0;

	@include md {
		font-size: 60px;
		line-height: 76px;
	}

	@include lg {
		font-size: 68px;
		line-height: 80px;
	}
}