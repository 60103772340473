@import "src/styles/defs";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: var(--x20);
	@include md {
		gap: var(--x30);
	}
}

.accordions {
	display: flex;
	flex-wrap: wrap;
	gap: var(--x4);
}