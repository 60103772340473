@import "src/styles/defs";

.container {
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: var(--x9);

	p {
		color: var(--text-color-secondary);
		line-height: 16px;
	}

	.username {
		color: var(--text-color-default);
		line-height: 14px;
	}
}

.divider {
	width: 100%;
	height: 1px;
	background-color: var(--border-secondary);
	margin: 0;
}

.balance {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: var(--x4);
}