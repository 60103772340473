@import "src/styles/defs";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.inner {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
	gap: var(--x4);

	@include md {
		gap: var(--x10);
		grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
	}
}
