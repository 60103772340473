@import "src/styles/defs";

.container {
	display: flex;
	flex-direction: column;
	gap: var(--x10);
	padding-left: var(--x6);
	padding-right: var(--x6);
	padding-bottom: var(--x6);
	padding-top: var(--x4);

	@include sm {
		margin-top: 0;
		gap: var(--x8);
		padding-left: var(--x8);
		padding-right: var(--x8);
		padding-top: var(--x8);
		padding-bottom: var(--x10);
	}
	@include md {
		margin-top: 0;
		gap: var(--x9);
		padding-left: var(--x10);
		padding-right: var(--x10);
		padding-top: var(--x7);
		padding-bottom: var(--x10);
	}
}