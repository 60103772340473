@import "src/styles/defs";

.p3 {
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.002em;
	font-family: $font_family_default;
	color: var(--text-color-secondary-light);
	margin: 0;

	@include md {
		font-size: 12px;
		line-height: 14px;
	}

	@include landscape{
		font-size: 10px;
		line-height: 12px;
	}
}

.bold {
	font-weight: 700;
}