@import "src/styles/defs";

.p1 {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.002em;
	font-family: $font_family_default;
	color: var(--text-color-default);
	margin: 0;

	@include md {
		font-size: 16px;
		line-height: 24px;
	}

	@include landscape {
		font-size: 14px;
		line-height: 20px;
	}
}

.bold {
	font-weight: 700;
}