.container {
	h1 {
		font-size: 18px;
	}

	.header {
		padding: 20px 0;
		margin-bottom: 20px;
		border-bottom: 1px dotted black;
		font-size: 24px;
		font-weight: bold;

	}

	.accept_nft_box {
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px dashed grey;

		.asset {
			display: inline-block;
			vertical-align: top;
			padding: 5px;
			box-shadow: 0 0 1px 1px black;
			text-align: center;

			img, video {
				width: 80px;
			}
		}

		span {
			display: block;
		}

	}

	.accept_token_box {
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px dashed grey;
	}

	.info {
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px dashed grey;
	}
}