/**
 * Do not edit directly
 * Generated on Thu, 16 Feb 2023 22:54:54 GMT
 */

:root {
  --x: 2px;
  --x2: 4px;
  --x3: 6px;
  --x4: 8px;
  --x5: 10px;
  --x6: 12px;
  --x7: 14px;
  --x8: 16px;
  --x9: 18px;
  --x10: 20px;
  --x12: 24px;
  --x14: 28px;
  --x15: 30px;
  --x16: 32px;
  --x20: 40px;
  --x24: 48px;
  --x28: 56px;
  --x30: 60px;
  --x32: 64px;
  --x40: 80px;
  --radius-xxs: 4px;
  --radius-xs: 6px;
  --radius-sm: 8px;
  --radius-m: 12px;
  --radius-l: 24px;
  --radius-xl: 60px;
  --icons-sm: 24px;
  --icons-md-lg: 32px;
  --shadow-blue: 4px 4px 0px #3A9CFE;
  --shadow-default: 8px 8px 0px #181818;
  --sys-primary-pr10: #D8EBFF;
  --sys-primary-pr30: #7FBFFE;
  --sys-primary-pr50: #3A9CFE;
  --sys-primary-pr70: #2E7DCB;
  --sys-primary-pr90: #173F66;
  --sys-tertiary-tr10: #FFECE8;
  --sys-tertiary-tr30: #FD9783;
  --sys-tertiary-tr50: #FC7D64;
  --sys-tertiary-tr70: #FC7D64;
  --sys-tertiary-tr90: #994C3D;
  --sys-secondary-sc10: #FBF9F4;
  --sys-secondary-sc30: #FFF3C5;
  --sys-secondary-sc50: #FFE486;
  --sys-secondary-sc70: #FFD74D;
  --sys-secondary-sc90: #D6A600;
  --sys-neutral-nt10: #FBFAF4;
  --sys-neutral-nt30: #C9C0C9;
  --sys-neutral-nt40: #7D737D;
  --sys-neutral-nt50: #656065;
  --sys-neutral-nt70: #444244;
  --sys-neutral-nt90: #282628;
  --sys-base-bs10: #FFFFFF;
  --sys-base-bs90: #181818;
  --text-color-default: #181818;
  --text-color-secondary: #444244;
  --text-color-secondary-light: #7D737D;
  --text-color-default-inverted: #FFFFFF;
  --text-color-disabled: #C9C0C9;
  --text-color-accent: #3A9CFE;
  --text-color-link: #3A9CFE;
  --text-color-error: #f35034;
  --buttons-primary: #3A9CFE;
  --buttons-accent: #181818;
  --buttons-secondary: #FBF9F4;
  --buttons-disabled: #444244;
  --bg-color-default: #FBFAF4;
  --bg-color-white: #FFFFFF;
  --bg-color-accent: #3A9CFE;
  --bg-color-surface-blue: #7FBFFE;
  --bg-color-error: #FFECE8;
  --bg-color-success: #F3FFE8;
  --border-buttons: #181818;
  --border-secondary: #C9C0C9;
  --border-link: #3A9CFE;
  --border-error: #f35034;
  --border-light: #FBFAF4;
  --border-success: #ADED6E;
  --x66: 132px;
  --x70: 140px;
  --shadow-gametools: 4px 4px 0px #13131029;
}
