@import "src/styles/defs";

.nav {
	position: relative;
	display: flex;
	align-items: center;
	padding: var(--x6) 0;
	justify-content: space-between;

	@include sm {
		padding: 12px 0 8px;
	}

	h4 {
		font-weight: 400;
		text-align: right;
		@include sm {
			text-align: center;
			flex: 1 0 30%;
		}
	}
}

.button {
	position: relative;
	left: initial;
	top: initial;
	transform: none;
	flex: 1 1 40%;
	@include sm {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

.balance {
	position: relative;
	right: initial;
	top: initial;
	transform: none;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: var(--x2);
	flex: 1 1 40%;

	@include sm {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		justify-content: flex-end;
		align-items: center;
		flex-direction: row;
	}

	span {
		display: flex;
		align-items: center;
		gap: var(--x2);
	}

	p {
		color: var(--text-color-secondary)
	}

	img {
		width: 18px;
		height: 18px;
	}
}