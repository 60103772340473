@import "src/styles/defs";

.counter {
	position: relative;
	display: flex;
	align-items: stretch;
	padding: var(--x6) var(--x5);
	gap: var(--x5);
	background: var(--bg-color-default);
	border: 2px solid var(--border-link);
	border-radius: var(--radius-xs);
	color: var(--text-color-default);

	@include sm {
		padding: var(--x8) var(--x5);
	}

	button {
		display: flex;
		padding: 0;
		background-color: transparent;
		border: none;
		align-self: center;
		cursor: pointer;

		&:disabled {
			cursor: default;
		}
	}

	input {
		display: flex;
		padding: 0;
		margin: 0;
		width: 0;
		flex: 1 1 auto;
		align-self: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.002em;
		color: inherit;
		border: none;
		background-color: transparent;
	}
}

.divider {
	width: 1px;
	display: block;
	min-height: 100%;
	background: var(--border-secondary);
	flex-shrink: 0;
}

.invalid {
	border: 2px solid var(--border-error);
	color: var(--text-color-error);
}

.disabled {
	border: 2px solid #C9C0C9;
	color: #C9C0C9;
}

.error {
	p {
		color: var(--text-color-error);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
