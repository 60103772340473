
.available_symbols {
	display: flex;

	div {
		padding: 10px;
		border: 1px solid;
		margin: 10px;
	}

	.active {
		background-color: red;
	}
}

.selected_sym {

}