.accept_nft_box {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px dashed grey;

	.asset, .selected_asset {
		display: inline-block;
		vertical-align: top;
		padding: 5px;
		box-shadow: 0 0 1px 1px black;
		text-align: center;

		img {
			width: 80px;
		}
	}

	.selected_asset {
		background-color: green;
	}

	span {
		display: block;
	}

}
