@import "src/styles/defs";

.container {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;

	@include lg {
		display: flex;
		align-items: center;
		flex-direction: row;
	}

	& > div {
		flex: 1 1 50%;
	}
}

.heading {
	display: flex;
	flex-direction: column;
	gap: var(--x12);
	align-items: center;
	justify-content: center;
	width: 100%;

	svg {
		transform: rotate(90deg);
		color: var(--sys-tertiary-tr70);
	}

	button {
		margin-top: var(--x15);
		width: 100%;
		@include lg {
			width: initial;
		}
	}


	@include lg {
		align-items: flex-start;
		text-align: left;
	}

}

.footer {
	display: flex;
	align-items: center;
	min-width: 100%;
	gap: var(--x24);
}

.buttons {
	display: flex;
	align-items: center;
	gap: var(--x12);
}

.info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-grow: 1;
}