@import "../../styles/defs";

.prizePool {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: var(--x2);
	background-color: var(--sys-secondary-sc50);
	border-radius: var(--radius-xxs);
	box-shadow: var(--shadow-gametools);
	padding: var(--x4) var(--x8);
	border: 1px solid #D6A600;
	position: relative;

	max-width: 480px;
	width: 100%;

	@include sm {
		padding: var(--x6) var(--x8);
	}

	@media (min-width: $header-max-width) {
		max-width: 100%;
	}
}

.openPopUp {
	position: absolute;
	right: 0;
	top: 0;

	&:hover {
		cursor: pointer;
		color: var(--buttons-primary);
	}

	width: 32px;
	height: 32px;
}
