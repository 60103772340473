@import "src/styles/defs";

.container {
	overflow: auto;
	height: 100%;
}

.layout {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-height: 100vh;
	padding: var(--x3);
	background-image: url("/images/expo_bg_img.png"), url("/images/noise.png");
	background-attachment: fixed;
	background-size: cover, 140px;
	background-position: center, center;
	background-repeat: no-repeat, repeat;
	background-color: var(--bg-color-default);
	width: 100%;

	@include md {
		padding: var(--x3) var(--x5);
	}

	@include lg {
		padding: var(--x3) var(--x10);
	}
}

.main {
	padding-top: 76px;
	flex-grow: 1;
	//background-image: url("/images/expo_bg_img.png");
	//background-position: center;
	//background-repeat: no-repeat;
	padding-bottom: 92px;
	max-width: $header-max-width;
	width: 100%;
	height: 100%;

	@include md {
		padding-top: 90px;
		padding-bottom: 104px;
	}
}

//canvas {
//	background: var(--bg-color-default) url("/images/canvas_ptrn.png");
//	background-size: 45px;
//}
