@import "src/styles/defs";

.col {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	background-color: var(--sys-neutral-nt30);
	border-radius: var(--radius-xs);
	border: 3px solid transparent;
	cursor: pointer;
	aspect-ratio: 1;

	padding: 3px;
	@include sm {
		padding: 10px;
	}

	&:hover, &:focus {
		border: 3px solid #3A9CFE;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.win {
	background-color: #3A9CFE;
}
