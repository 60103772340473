@import "src/styles/defs";

.container {
	margin: 0 auto;
	width: 100%;
	gap: var(--x24);
	max-width: calc(576px + var(--x30));
	padding: var(--x28) var(--x6) var(--x16);

	@include md {
		padding: var(--x32) var(--x15);
	}

	@include lg {
		max-width: 1440px;
		padding: var(--x40) var(--x30);
	}
}

.fluid {
	max-width: 1440px;
	@include md {
		max-width: 1440px;
	}
}

.bg {
	background-color: var(--bg-color-default);
}

